import ReactDOM from "react-dom";
import "./index.css";

import Finished from "./Finished";

ReactDOM.render(
  <div className="flex flex-col gap-3 justify-center items-center container mx-auto h-full">
    <Finished />
  </div>,
  document.getElementById("root")
);
