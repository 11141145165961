import axios from "axios";
import { useEffect, useState } from "react";

const Finished = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setdata] = useState(null)

  const tenant = window.location.pathname.split("/");

  const url = new URL(window.location.href);
  const sessionId = url.searchParams.get("session_id");

  const urlToShow = `https://web.getbee.com/${sessionId ? "joining-appointment?sessionId=" + sessionId : ""}`

  const fetchTenantInfo = async () => {

    setIsLoading(true)
    try {
      const response = await axios.get(`https://api.getbee.com/v2/Tenant/customization?tenant=${tenant?.[1]}`)
      if (response?.data?.isSucceeded) {
        setdata(response?.data)
      }
    } catch (error) {

    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchTenantInfo()
  }, [])

  if (isLoading) {
    return <p className="text-lg font-semibold">Loading...</p>
  }

  if (!!data) {
    return <><p className="text-lg font-normal px-10 w-full max-w-4xl mx-auto text-center">This is an expired link. If you are trying to access your appointment please <a href={data?.widgetBaseUrl}>click here</a><br />(if you are hosting an appointment please login here: <a href={urlToShow}>https://web.getbee.com/</a>)
    </p ></>
  }

  return <><p className="text-lg font-normal px-10 w-full max-w-4xl mx-auto text-center">This is an expired link. Please login here: <a href="https://web.getbee.com/">https://web.getbee.com/</a></p></>;
};

//

export default Finished;
